import React, { ReactElement } from "react";

const BulletIcon = (): ReactElement => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 3C5.5 4.38071 4.38071 5.5 3 5.5C1.61929 5.5 0.5 4.38071 0.5 3C0.5 1.61929 1.61929 0.5 3 0.5C4.38071 0.5 5.5 1.61929 5.5 3Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);

export default BulletIcon;
