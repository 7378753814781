import styled from "styled-components";

const StyledEditSpan = styled.span`
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 0.5s;
  cursor: pointer;
`;

export default StyledEditSpan;
