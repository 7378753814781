import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

const StyledControlBar = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  padding: 3rem;
  display: flex;
  flex-direction: row;
`;

interface ControlBarProps {
  children: ReactNode;
}

const ControlBar = ({ children }: ControlBarProps): ReactElement => {
  return <StyledControlBar>{children}</StyledControlBar>;
};

export default ControlBar;
