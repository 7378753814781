import { TokenInterface } from "../interfaces/TokenInterface";
import client from "./client";
import getApiUrl from "../helpers/getApiUrl";

interface UsePostLoginInterface {
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<TokenInterface | null>;
}

const usePostLogin = (): UsePostLoginInterface => {
  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<TokenInterface | null> => {
    // todo outsource to useLoggedOutApi or similar
    const { status, data: tokenData } = await client.post(
      `${getApiUrl()}/authentication/login`,
      {
        email,
        password,
      }
    );

    if (status === 200) {
      return tokenData;
    }

    return null;
  };

  return { login };
};

export default usePostLogin;
