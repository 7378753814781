import styled from "styled-components";

const StyledTr = styled.tr<{ hoverEffect?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors["black/5"]};
  transition: all 0.25s ease-in-out;

  &:hover {
    background: ${({ hoverEffect, theme }) =>
      hoverEffect === true ? theme.colors.light : "transparent"};
  }
`;

StyledTr.defaultProps = {
  hoverEffect: true,
};

export default StyledTr;
