import styled from "styled-components";

const StyledTh = styled.th`
  padding: 1rem 2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors["black/40"]};
  text-align: left;
  border-right: 1px solid ${({ theme }) => theme.colors["black/5"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  &:last-child {
    border-right: none;
  }
`;

export default StyledTh;
