import React, { ReactElement, ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";
import "inter-ui/inter.css";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${(props) => props.theme.sizes.basic};
  }
  @supports (font-variation-settings: normal) {
    html { font-family: "Inter var", "system-ui"; }
  }

  body {
    background-color: ${(props) => props.theme.colors.white};
    font-size: 1.75rem;
    font-family: "Inter", "system-ui", sans-serif;
    font-weight:400;
    line-height: 1.75rem;
    color: ${(props) => props.theme.colors.black}
  }
`;

interface LayoutProps {
  children: ReactNode;
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const Layout = ({ children }: LayoutProps): ReactElement => {
  return (
    <>
      <GlobalStyle />
      <StyledDiv>{children}</StyledDiv>
    </>
  );
};

export default Layout;
