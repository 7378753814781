import React, { ReactElement } from "react";
import { Headline, Sidebar } from "../components";

interface RightSidebarProps {
  isShowRightSidebar: boolean;
}

const RightSidebar = ({
  isShowRightSidebar,
}: RightSidebarProps): ReactElement => {
  return (
    <>
      {isShowRightSidebar && (
        <Sidebar position="right">
          <Headline>Benachrichtigungen</Headline>
        </Sidebar>
      )}
    </>
  );
};

export default RightSidebar;
