import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

type SidebarPosition = "left" | "right";

const StyledSidebar = styled.div<{ position: SidebarPosition }>`
  padding: 3rem;
  min-width: 28rem;
  border-${({ position }) =>
    position === "left" ? "right" : "left"}: 1px solid ${(props) =>
  props.theme.colors.gray};
  flex-shrink: 0;
`;

interface SidebarProps {
  children: ReactNode;
  position?: SidebarPosition;
}

const Sidebar = ({
  children,
  position = "left",
}: SidebarProps): ReactElement => {
  return <StyledSidebar position={position}>{children}</StyledSidebar>;
};

export default Sidebar;
