import styled from "styled-components";

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.75rem;
`;

export default StyledRow;
