import React, { ReactElement } from "react";
import styled from "styled-components";
import StyledButton from "./StyledButton";
import StyledSidebarListElement from "./StyledSidebarListElement";

interface SidebarListElementProps {
  children: string;
  onClick: () => void;
}

const StyledSubListButton = styled(StyledButton)`
  &::before {
    content: "";
    padding-right: 2.5rem;
  }
`;

const SidebarSubListElement = ({
  children,
  onClick,
}: SidebarListElementProps): ReactElement => {
  return (
    <StyledSidebarListElement>
      <StyledSubListButton onClick={onClick}>{children}</StyledSubListButton>
    </StyledSidebarListElement>
  );
};

export default SidebarSubListElement;
