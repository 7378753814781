import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface RightProps {
  children: ReactNode;
}

const StyledRightDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const Right = ({ children }: RightProps): ReactElement => {
  return <StyledRightDiv>{children}</StyledRightDiv>;
};

export default Right;
