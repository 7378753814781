import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

interface MainContentProps {
  children: ReactNode;
}

const StyledMainContent = styled.div`
  flex-grow: 1;
  min-height: 100vh;
`;

const MainColumn = ({ children }: MainContentProps): ReactElement => {
  return <StyledMainContent>{children}</StyledMainContent>;
};

export default MainColumn;
