import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { SidebarHeading } from "../index";
import Text from "../Text";

interface SidebarListProps {
  children: ReactNode;
  title: string;
}

const StyledList = styled.ul`
  margin: 1rem 0 0 0;
  padding: 0;
`;

const StyledContainer = styled.div`
  margin-bottom: 4rem;
`;

const SidebarList = ({ children, title }: SidebarListProps): ReactElement => {
  return (
    <StyledContainer>
      <SidebarHeading>
        <Text variant={"light"}>{title}</Text>
      </SidebarHeading>
      <StyledList>{children}</StyledList>
    </StyledContainer>
  );
};

export default SidebarList;
