import React, { ReactElement, ReactNode, useState } from "react";
import { SidebarToggleListTitle } from "../index";
import StyledSidebarListElement from "./StyledSidebarListElement";

interface SidebarToggleListProps {
  children: ReactNode;
  title: string;
}

const SidebarToggleList = ({
  children,
  title,
}: SidebarToggleListProps): ReactElement => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <>
      <StyledSidebarListElement>
        <SidebarToggleListTitle
          title={title}
          isOpened={isOpened}
          toggle={() => setIsOpened(!isOpened)}
        >
          {children}
        </SidebarToggleListTitle>
      </StyledSidebarListElement>
    </>
  );
};

export default SidebarToggleList;
