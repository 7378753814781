import { useState } from "react";

interface SidebarSettingsInterface {
  isShowLeft: boolean;
  isShowRight: boolean;
}

type SidebarPositionType = "left" | "right";

interface UseSidebarInterface {
  sidebarSettings: SidebarSettingsInterface;
  toggleSidebar: ({ position }: { position: SidebarPositionType }) => void;
}

const useSidebar = (): UseSidebarInterface => {
  const [sidebarSettings, setSidebarSettings] =
    useState<SidebarSettingsInterface>({
      isShowLeft: true,
      isShowRight: false,
    });

  const toggleSidebar = ({
    position,
  }: {
    position: SidebarPositionType;
  }): void => {
    setSidebarSettings({
      isShowLeft:
        position === "left"
          ? !sidebarSettings.isShowLeft
          : sidebarSettings.isShowLeft,
      isShowRight:
        position === "right"
          ? !sidebarSettings.isShowRight
          : sidebarSettings.isShowRight,
    });
  };

  return { sidebarSettings, toggleSidebar };
};

export default useSidebar;
