import styled from "styled-components";

const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -1rem;
  padding-bottom: 3rem;
`;

export default StyledLogoWrapper;
