import React, { ReactElement } from "react";

const SidebarIcon = (): ReactElement => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.125 13.25C1.95921 13.25 1.80029 13.1842 1.68303 13.067C1.56584 12.9497 1.5 12.7908 1.5 12.625V1.375C1.5 1.20921 1.56584 1.05029 1.68303 0.933029C1.80029 0.815842 1.95921 0.75 2.125 0.75H5.875V13.25H2.125Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M5.25 0.75V13.25C5.25 13.5952 5.52982 13.875 5.875 13.875C6.22018 13.875 6.5 13.5952 6.5 13.25V0.75C6.5 0.404822 6.22018 0.125 5.875 0.125C5.52982 0.125 5.25 0.404822 5.25 0.75Z"
      fill="#1C1C1C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.875 1.375C0.875 1.375 0.875 0.857233 1.24112 0.491117C1.24112 0.491117 1.60723 0.125 2.125 0.125H15.875C15.875 0.125 16.3928 0.125 16.7589 0.491117C16.7589 0.491117 17.125 0.857232 17.125 1.375V12.625C17.125 12.625 17.125 13.1428 16.7589 13.5089C16.7589 13.5089 16.3928 13.875 15.875 13.875H2.125C2.125 13.875 1.60723 13.875 1.24112 13.5089C1.24112 13.5089 0.875 13.1428 0.875 12.625V1.375ZM2.125 1.375V12.625H15.875V1.375H2.125Z"
      fill="#1C1C1C"
    />
    <path
      d="M1.5 3.875H3.375C3.72018 3.875 4 3.59518 4 3.25C4 2.90482 3.72018 2.625 3.375 2.625H1.5C1.15482 2.625 0.875 2.90482 0.875 3.25C0.875 3.59518 1.15482 3.875 1.5 3.875Z"
      fill="#1C1C1C"
    />
    <path
      d="M1.5 6.375H3.375C3.72018 6.375 4 6.09518 4 5.75C4 5.40482 3.72018 5.125 3.375 5.125H1.5C1.15482 5.125 0.875 5.40482 0.875 5.75C0.875 6.09518 1.15482 6.375 1.5 6.375Z"
      fill="#1C1C1C"
    />
    <path
      d="M1.5 8.875H3.375C3.72018 8.875 4 8.59518 4 8.25C4 7.90482 3.72018 7.625 3.375 7.625H1.5C1.15482 7.625 0.875 7.90482 0.875 8.25C0.875 8.59518 1.15482 8.875 1.5 8.875Z"
      fill="#1C1C1C"
    />
  </svg>
);

export default SidebarIcon;
