import React, {
  ChangeEvent,
  FocusEventHandler,
  ReactElement,
  ReactNode,
} from "react";
import StyledField from "../styles/StyledField";
import StyledLabel from "../styles/StyledLabel";
import styled from "styled-components";

interface SelectFieldProps {
  children: ReactNode;
  label: string;
  name: string;
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleBlur?: FocusEventHandler<HTMLSelectElement>;
  value: any;
}

const StyledSelectField = styled.select`
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
`;

const SelectField = ({
  label,
  name,
  handleChange,
  handleBlur,
  value,
  children,
}: SelectFieldProps): ReactElement => {
  return (
    <StyledField>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelectField
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      >
        {children}
      </StyledSelectField>
    </StyledField>
  );
};

export default SelectField;
