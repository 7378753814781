import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { svgComponentToMarkupString } from "../../helpers";
import { ChevronDownIcon, ChevronRightIcon } from "../../icons";
import StyledButton from "./StyledButton";

interface SidebarToggleListProps {
  children: ReactNode;
  title: string;
  toggle: () => void;
  isOpened: boolean;
}

const StyledTitle = styled(StyledButton)<{ isOpened: boolean }>`
  &::before {
    content: url(${({ isOpened }) =>
      svgComponentToMarkupString(
        isOpened ? ChevronDownIcon : ChevronRightIcon
      )});
  }
`;

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
`;

const SidebarToggleListTitle = ({
  children,
  title,
  toggle,
  isOpened,
}: SidebarToggleListProps): ReactElement => {
  return (
    <>
      <StyledTitle onClick={toggle} isOpened={isOpened}>
        {title}
      </StyledTitle>
      {isOpened && <StyledList>{children}</StyledList>}
    </>
  );
};

export default SidebarToggleListTitle;
