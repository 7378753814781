import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledSpace = styled.div<{ size: number }>`
  height: ${({ size }) => size}rem;
`;

interface SpaceProps {
  size?: number;
}
const Space = ({ size = 1 }: SpaceProps): ReactElement => {
  return <StyledSpace size={size} />;
};

export default Space;
