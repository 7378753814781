import { useLocation } from "react-router-dom";

interface UseBreadcrumbsInterface {
  breadcrumbs: string[];
}

const useBreadcrumbs = (): UseBreadcrumbsInterface => {
  const { pathname } = useLocation();

  const [, ...breadcrumbs] = pathname.split("/");
  return { breadcrumbs };
};

export default useBreadcrumbs;
