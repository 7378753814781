import styled from "styled-components";
import { svgComponentToMarkupString } from "../../helpers";
import { BulletIcon } from "../../icons";

const StyledButton = styled.span<{ isActive?: boolean }>`
  display: block;
  padding: 1rem 2rem;
  margin-left: -2rem;
  border-radius: 1rem;
  transition: background-color 0.5s;
  cursor: pointer;
  background: ${({ theme, isActive }) =>
    isActive === undefined || !isActive
      ? "transparent"
      : theme.colors["black/5"]};

  &:hover {
    background: ${({ theme }) => theme.colors["black/5"]};
  }

  &::before {
    content: url(${svgComponentToMarkupString(BulletIcon)});
    color: ${({ theme }) => theme.colors["black/20"]};
    width: 1rem;
    padding-right: 2rem;
  }
`;

export default StyledButton;
