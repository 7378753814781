import React, { ReactElement, ReactNode } from "react";
import StyledButton from "./StyledButton";
import StyledSidebarListElement from "./StyledSidebarListElement";
import styled from "styled-components";

interface SidebarListElementProps {
  children: ReactNode;
  onClick: () => void;
  isActive: boolean;
}

const AbDiv = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.black};
  width: 0.5rem;
  height: 1.75rem;
  margin-top: -1.75rem;
  margin-left: -2rem;
  border-radius: 1rem;
`;

const ActiveMarker = (): ReactElement => {
  return <AbDiv />;
};

const SidebarListElement = ({
  children,
  onClick,
  isActive,
}: SidebarListElementProps): ReactElement => {
  return (
    <StyledSidebarListElement>
      <StyledButton isActive={isActive} onClick={onClick}>
        {isActive && <ActiveMarker />}
        {children}
      </StyledButton>
    </StyledSidebarListElement>
  );
};

export default SidebarListElement;
