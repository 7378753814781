import React, { ReactElement, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useSidebar from "../hooks/useSidebar";
import { Layout, MainColumn, StyledContent } from "../components";
import Navigation from "./Navigation";
import Breadcrumbs from "./Breadcrumbs";
import useNavigation from "../hooks/useNavigation";
import LeftSidebar from "./LeftSidebar";
import RightSidebar from "./RightSidebar";
import useAuthentication from "../api/useAuthentication";

const LoggedInApp = (): ReactElement => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthentication();
  const { navigation } = useNavigation();
  const {
    toggleSidebar,
    sidebarSettings: {
      isShowRight: isShowRightSidebar,
      isShowLeft: isShowLeftSidebar,
    },
  } = useSidebar();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return (
    <Layout>
      <LeftSidebar isShowLeftSidebar={isShowLeftSidebar}>
        {navigation.map(({ items, label }) => (
          <Navigation
            title={label}
            navigation={items}
            key={label}
            onNavigationChange={navigate}
            currentRoute={location.pathname}
          />
        ))}
      </LeftSidebar>
      <MainColumn>
        <Breadcrumbs
          isActive={isShowLeftSidebar}
          handleToggleClick={() => toggleSidebar({ position: "left" })}
        />
        <StyledContent>
          <Outlet />
        </StyledContent>
      </MainColumn>
      <RightSidebar isShowRightSidebar={isShowRightSidebar} />
    </Layout>
  );
};

export default LoggedInApp;
