import React, { ReactElement } from "react";

const ChevronDownIcon = (): ReactElement => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.56694 0.683058C7.81102 0.927136 7.81102 1.32286 7.56694 1.56694L4.44194 4.69194C4.19786 4.93602 3.80214 4.93602 3.55806 4.69194L0.433058 1.56694C0.18898 1.32286 0.18898 0.927136 0.433058 0.683058C0.677136 0.438981 1.07286 0.438981 1.31694 0.683058L4 3.36612L6.68306 0.683058C6.92714 0.438981 7.32286 0.438981 7.56694 0.683058Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);

export default ChevronDownIcon;
