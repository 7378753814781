import React, { ReactElement } from "react";

const CheckIcon = (): ReactElement => (
  <>
    <svg
      width="14"
      height="14"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70748 15.2933L6.70711 15.2929C6.51957 15.1054 6.26522 15 6 15C5.73478 15 5.48043 15.1054 5.29289 15.2929C5.10536 15.4804 5 15.7348 5 16C5 16.016 5.00038 16.032 5.00115 16.048C5.0131 16.2964 5.1171 16.5313 5.29289 16.7071L5.29327 16.7075L12.2929 23.7071C12.6834 24.0976 13.3166 24.0976 13.7071 23.7071L27.7071 9.70711C27.8946 9.51957 28 9.26522 28 9C28 8.73478 27.8946 8.48043 27.7071 8.29289C27.5196 8.10536 27.2652 8 27 8C26.7348 8 26.4804 8.10536 26.2929 8.29289L13 21.5858L6.70748 15.2933Z"
        fill="#ffffff"
      />
    </svg>
  </>
);

export default CheckIcon;
