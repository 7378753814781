import styled from "styled-components";

const StyledIconButton = styled.a<{ isActive: boolean }>`
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 1rem;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors["black/5"] : "transparent"};
  margin-right: 1rem;
  cursor: pointer;
  line-height: 1rem;
`;

export default StyledIconButton;
