import React, { ReactElement, ReactNode } from "react";

interface SidebarHeadingProps {
  children: ReactNode;
}
const SidebarHeading = ({ children }: SidebarHeadingProps): ReactElement => (
  <div>{children}</div>
);

export default SidebarHeading;
